import React from "react";
import LeftSideBar from "../../Components/Common/LeftSideBar";
import Footer from "../../Components/Common/Footer";
import TopBar from "../../Components/Common/TopBar";
import PageHeader from "../../Components/Common/PageHeader";
import DashCard from "../../Components/Common/DashCard";
import PopAddPrice from "../../Components/Betting/PopAddPrice";
import PopAddMatch from "../../Components/Betting/PopAddMatch";
import PopAddPrediction from "../../Components/Betting/PopAddPrediction";
import Matches from "../../Components/Betting/Matches";
import functions from "../../functions";
import ajax from "../../ajax";
import RightSideBar from "../../Components/Common/RightSideBar";
import $ from "jquery";
import ActivityLoader from "../../Components/Common/ActivityLoader";
import PassedTips from "../../Components/Betting/PassedTips";
import TitleValueCard from '../../Components/Common/TitleValueCard'
import Tips from "../../Components/Betting/Tips";
import ContactSearch from "../../Components/Betting/ContactSearch";
import Statistics from "../../Components/Betting/Statistics";

class BettingDashboard extends React.Component {
  state = {
    userId: functions.sessionGuard(),
    addPrice: false,
    addMatch: false,
    addPrediction: false,
    prices: false,
    totalMatches: '...',
    totalMatchWins: '...',
    totalSuccessMessages: '...',
    totalUnsuccessMessages: '...'

  };

  componentDidMount() {
    this.BettingPredictionsList();
    this.BettingPricesList();
    this.setTotalMatches();
    this.setTotalMatchWins();
    this.setTotalSuccessMessages();
    this.setTotalUnSuccessMessages();
  }


  setTotalMatches = async () => {
    const server_response = await ajax.countMatches();
    if (server_response.status === "OK") {
      this.setState({
        totalMatches: server_response.details.total_p,
      });
    }
  };

  setTotalMatchWins = async () => {
    const server_response = await ajax.countMatchWins();
    if (server_response.status === "OK") {
      this.setState({
        totalMatchWins: server_response.details.total_p,
      });
    }
  };


  setTotalSuccessMessages = async () => {
    const server_response = await ajax.countPaidTips();
    if (server_response.status === "OK") {
      this.setState({
        totalSuccessMessages: server_response.details.total_p,
      });
    }
  };


  setTotalUnSuccessMessages = async () => {
    const server_response = await ajax.countUnPaidTips();
    if (server_response.status === "OK") {
      this.setState({
        totalUnsuccessMessages: server_response.details.total_p,
      });
    }
  };

  BettingPredictionsList = async () => {
    const server_response = await ajax.BettingPredictionsList();
    if (server_response.status === "OK") {
      this.setState({
        predictions: server_response.details,
      });
    } else {
      this.setState({
        predictions: "404",
      });
    }
  };

  BettingPricesList = async () => {
    const server_response = await ajax.BettingPricesList();
    if (server_response.status === "OK") {
      this.setState({
        prices: server_response.details,
      });
    } else {
      this.setState({
        prices: "404",
      });
    }
  };

  buttons = () => {
    return (
      <div className="btn btn-list">
        <a
          className="btn ripple btn-success"
          href="#"
          onClick={this.onClickAddPrice}
        >
          <i className="fe fe-plus-square"></i> Add Price
        </a>
        <a
          className="btn ripple btn-warning"
          href="#"
          onClick={this.onClickAddPrediction}
        >
          <i className="fe fe-plus-square"></i> Add Prediction
        </a>

        <a
          className="btn ripple btn-danger"
          href="#"
          onClick={this.onClickAddMatch}
        >
          <i className="fe fe-plus-circle"></i> Add Match
        </a>
      </div>
    );
  };

  onClickAddPrice = () => {
    this.setState(
      {
        addPrice: <PopAddPrice />,
      },
      () => {
        $("#modal_add_price").modal("show");
      }
    );
  };

  onClickAddMatch = () => {
    this.setState(
      {
        addMatch: <PopAddMatch />,
      },
      () => {
        $("#modal_add_match").modal("show");
      }
    );
  };

  onClickAddPrediction = () => {
    this.setState(
      {
        addPrediction: <PopAddPrediction />,
      },
      () => {
        $("#modal_add_prediction").modal("show");
      }
    );
  };

  render() {
    const { prices } = this.state;
    return (
      <div className="page">
        {this.state.addPrice}
        {this.state.setResult}
        {this.state.addMatch}
        {this.state.addPrediction}
        <LeftSideBar />
        <div
          className="main-content side-content pt-0"
          onClick={functions.closeSystemDrawer}
        >
          <TopBar />
          <div className="container-fluid">
            <PageHeader
              title="Betting Tips Admin Dashboard"
              previousPageTitle="Dashboard"
              buttons={<this.buttons />}
            />



            {/* <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="row">
                  <TitleValueCard colSize={3} href="#" title="Successful Messages" value={this.state.totalSuccessMessages} icon="fas fa-envelope-open" color="icon-service rounded-circle bg-success-transparent text-success" />
                  <TitleValueCard colSize={3} href="#" title="Unsuccessful Messages" value={this.state.totalUnsuccessMessages} icon="fas fa-envelope" color="icon-service rounded-circle bg-danger-transparent text-danger" />
                  <TitleValueCard colSize={3} href="#" title="Total Matches" value={this.state.totalMatches} icon="fas fa-baseball-ball" color="icon-service rounded-circle bg-primary-transparent text-primary" />
                  <TitleValueCard colSize={3} href="#" title="Match Wins" value={this.state.totalMatchWins} icon="fas fa-baseball-ball" color="icon-service rounded-circle bg-success-transparent text-success" />

                </div>
              </div>
            </div> */}

            <div className="row">
              {/* <div class=" col-lg-12 col-md-12">
                <div class="card custom-card">
                  <div class="row">
                    {!prices && <ActivityLoader />}
                    {prices &&
                      Array.isArray(prices) &&
                      prices.map((price, key) => (
                        <div
                          class="col-md-3 pr-0 pl-0 border-right"
                        >
                          <div class="card-body text-center">
                            <h6 class="mb-0">{price.price_name}</h6>
                            <h2 class="mb-1 mt-2 number-font">
                              <span class="counter">
                                {price.amount.amount_c}
                              </span>
                            </h2>
                            <br />
                            <p class="mb-0 text-muted">
                              <span class="mb-0 text-success fs-13 ml-1">
                                Total Matches
                              </span>{" "}
                              {price.match_count.total_p}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div> */}

              <Statistics />

              {/* <Matches /> */}

              <PassedTips />

              <Tips />
            </div>












          </div>

        </div>
        <ContactSearch />
        <RightSideBar />
        <Footer />
      </div>
    );
  }
}

export default BettingDashboard;
