import React from "react";
import { Link } from "react-router-dom";
import functions from "../../functions";

class PageHeader extends React.Component {
  render() {
    const accessInfo = functions.readPermissions();
    return (
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            {this.props.title}
          </h2>
          {this.props.previousPageTitle && (
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={this.props.back}>{this.props.previousPageTitle}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {this.props.title}
              </li>
            </ol>
          )}
        </div>

        <div className="btn btn-list">
          {/* {this.props.buttons} */}

          {/* {this.props.options && (
            <>
              <a
                className="btn ripple btn-danger dropdown-toggle"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <i className="fe fe-settings"></i> Options{" "}
                <i className="fas fa-caret-down ml-1"></i>
              </a>
              <div className="dropdown-menu tx-13">
                {this.props.options.profile && (
                  <a
                    className="dropdown-item"
                    onClick={this.props.options.profile}
                  >
                    <i className="fas fa-user mr-2"></i>Profile
                  </a>
                )}
                {this.props.options.history && (
                  <a
                    className="dropdown-item"
                    onClick={this.props.options.history}
                  >
                    <i className="far fa-clock mr-2"></i>Loan History
                  </a>
                )}
                {this.props.options.transactions && (
                  <a
                    className="dropdown-item"
                    onClick={this.props.options.transactions}
                  >
                    <i className="fas fa-exchange-alt mr-2"></i>Wallet Statement
                  </a>
                )}
                {this.props.options.statistics && (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: `/customer-stat/${this.props.customerId}`,
                      state: {
                        customerId: this.props.customerId,
                        customer: true,
                      },
                    }}
                    replace
                  >
                    <i className="fas fa-chart-bar mr-2"></i>Customer Statistics
                  </Link>
                )}
                {this.props.options.agentStatistics && (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: `/customer-stat/${this.props.agentId}`,
                      state: { customerId: this.props.agentId, agent: true },
                    }}
                    replace
                  >
                    <i className="fas fa-chart-bar mr-2"></i>Agent Statistics
                  </Link>
                )}
                {this.props.options.bFloat && (
                  <a
                    className="dropdown-item"
                    onClick={this.props.options.bFloat}
                  >
                    <i className="fas fa-exchange-alt mr-2"></i>Business Float
                  </a>
                )}
                {this.props.options.cFloat && (
                  <a
                    className="dropdown-item"
                    onClick={this.props.options.cFloat}
                  >
                    <i className="fas fa-exchange-alt mr-2"></i>Capital Float
                  </a>
                )}

                {this.props.options.pinReset &&
                  functions.findInObject(accessInfo, "PIN-RESET-1") && (
                    <a
                      className="dropdown-item"
                      onClick={this.props.options.pinReset}
                    >
                      <i className="fas fa-lock-open mr-2"></i>Reset PIN
                    </a>
                  )}

                {this.props.options.changeLimit &&
                  functions.findInObject(accessInfo, "CHANGE-LOAN-LIMIT") && (
                    <a
                      className="dropdown-item"
                      onClick={this.props.options.changeLimit}
                    >
                      <i className="far fa-flag mr-2"></i>Change Loan Limit
                    </a>
                  )}
                {this.props.options.userLocation &&
                  functions.findInObject(accessInfo, "USER-LCTN-0001") && (
                    <a
                      className="dropdown-item"
                      onClick={this.props.options.userLocation}
                    >
                      <i className="fas fa-map-marker-alt mr-2"></i>Last User
                      Location
                    </a>
                  )}

                {this.props.options.customOptions}
              </div>
            </>
          )} */}
        </div>
      </div>
    );
  }
}

export default PageHeader;
